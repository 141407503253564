import { createRouter, createWebHistory } from 'vue-router'
import {
  userStatusGaurd,
  userAccessGaurd,
  isAuthorizedGaurd,
  hasActiveSessionGaurd
} from '@/router/userStatusGaurd'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'login',
      beforeEnter: [hasActiveSessionGaurd],
      component: () => import('@/views/publisher/onboard/onboard_views/LoginView.vue')
    },
    {
      path: '/onboard',
      name: 'Onboard',
      beforeEnter: [hasActiveSessionGaurd],
      component: () => import('@/views/publisher/onboard/onboard_views/OnboardInitiate.vue')
    },

    {
      path: '/app',
      name: 'App',
      component: () => import('@/App.vue'),
      beforeEnter: [isAuthorizedGaurd, userAccessGaurd],
      children: []
    },
    {
      path: '/setup',
      name: 'Setup',
      component: () => import('@/App.vue'),
      beforeEnter: [isAuthorizedGaurd, userStatusGaurd],
      children: [
        {
          path: 'email-verify',
          name: 'Verification',
          meta: { statusNeeded: 'pub_new_onplad_created' },
          component: () => import('@/views/publisher/onboard/onboard_views/EmailVerification.vue')
        },
        {
          path: 'company-profile',
          name: 'CompanyProfile',
          meta: { statusNeeded: 'pub_new_onplad' },
          component: () => import('@/views/publisher/onboard/onboard_views/CompanyProfile.vue')
        },
        {
          path: 'bank-details',
          name: 'BankDetails',
          meta: { statusNeeded: 'pub_company_profile_done' },
          component: () => import('@/views/publisher/onboard/onboard_views/BankDetails.vue')
        },
        {
          path: 'documents',
          name: 'DocumentsUpload',
          meta: { statusNeeded: 'pub_bank_details_done' },
          component: () => import('@/views/publisher/onboard/onboard_views/DocumentsUpload.vue')
        },
        {
          path: 'profile-verify',
          name: 'ProfileVerification',
          meta: { statusNeeded: 'pub_under_verification' },
          component: () => import('@/views/publisher/onboard/onboard_views/ProfileVerification.vue')
        }
      ]
    },
    {
      path: '/onplad',
      name: 'Onplad',
      component: () => import('@/OnpladApp.vue'),
      beforeEnter: [isAuthorizedGaurd],
      children: [
        {
          path: 'dashboard',
          name: 'DashboardOverview',
          component: () => import('@/views/dashboard/Overview.vue')
        },

        {
          path: 'campaign',
          name: 'PublisherCampaign',
          component: () => import('@/views/publisher/orders/CampaignView.vue')
        },
        {
          path: 'profile',
          name: 'PublisherProfile',
          component: () => import('@/views/publisher/profile/ProfileView.vue')
        },
        {
          path: 'metrics',
          name: 'CompanyMetrics',
          component: () => import('@/views/publisher/profile/MetricView.vue')
        },
        {
          path: 'publisher', // Keep this as the parent route
          name: 'PublisherOverview',
          component: () => import('@/views/publisher/overview/SummaryView.vue'),
          children: [
            {
              path: 'orders', // Nest PublisherOrders route under PublisherOverview
              name: 'PublisherOrders',
              component: () => import('@/views/publisher/orders/CampaignView.vue')
            }
          ]
        }
      ]
    },

    {
      path: '/no-access',
      component: () => import('@/views/NoAccess.vue')
    }
  ]
})

export default router
